import React, { Fragment } from "react";
import ReactRoundedImage from "react-rounded-image";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i4 from "./assets/images/Logo.png";
import i9 from "./assets/images/opensea.png";
import i10 from "./assets/images/etherscan.png";
import i11 from "./assets/images/discord.png";
import i12 from "./assets/images/twitter.png";
import i13 from "./assets/images/Artist.png";
import i14 from "./assets/images/440.png";
import i15 from "./assets/images/105.png";
import i16 from "./assets/images/Dev.png";
import i17 from "./assets/images/34.png";
import i1 from "./assets/images/48.png";
import i18 from "./assets/images/234.png";
import i21 from "./assets/images/264.png";
import i22 from "./assets/images/logowhite.png";
import i23 from "./assets/images/Neo.png";
import i24 from "./assets/images/1.png";
import i25 from "./assets/images/8.png";
import i26 from "./assets/images/12.png";
import i27 from "./assets/images/59.png";
import i28 from "./assets/images/117.png";
import i29 from "./assets/images/400.png";
import i30 from "./assets/images/326.png";
import Navbar from "./components/NavBar";
import Gradient from "rgt";
import background from "./assets/images/bricks3a.png";

export const StyledFrame = styled.iframe`
  allow: fullscreen;
  scrolling: no;
  width: 3000px;
  height: 960px;
  position: relative;
  overflow: hidden;
`;

export const SytledInput = styled.input`
  font-size: 22px;
  padding: 10px;
  width: 75px;
  margin: 10px;
  height: 50px;
  color: #00dfd8;
  outline: none;
  background: #111;
  border: #ff0000;
  border-radius: 10px;
  border-color: #ff0000;
  box-shadow: 0 0 10px #719ece;
`;

export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ff0000;
  box-shadow: 0 0 10px #719ece;
  outline: none;
  color: #00dfd8;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const ResponsiveWrapper = styled.div`
  width: 2000px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 50%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  return (
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <Fragment>
        <Navbar />
        <div style={{ height: "px" }}></div>
      </Fragment>

      <s.Container ai={""} jc={""}>
        <s.Container
          ai={""}
          jc={""}
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            overflow: "",
            //maxHeight: "887px",
            //minHeight: "887px",
            Width: "100%",
          }}
        >
          <s.SpacerMedium />
          <s.Container
            ai={"left"}
            jc={""}
            style={{
              marginLeft: "",
              flexWrap: "",
              overflow: "hidden",
              maxHeight: "887px",
              minHeight: "155px",
              maxWidth: "2560px",
              minwidth: "100px",
            }}
          >
            <s.Container
              ai={""}
              jc={""}
              fd={"row"}
              style={{
                marginLeft: "235px",
                flexWrap: "",
                overflow: "",
                maxHeight: "130px",
                //minHeight: "130px",
                maxWidth: "399px",
              }}
            >
              <ReactRoundedImage
                image={i4}
                borderRadius="0"
                roundedColor=""
                imageWidth="399"
                imageHeight="130"
                roundedSize=""
                hoverColor=""
              />
            </s.Container>

            <s.Container
              ai={""}
              jc={""}
              fd={"row"}
              style={{
                marginTop: "5px",
                marginLeft: "478px",
                flexWrap: "",
                overflow: "",
                maxHeight: "35px",
                minHeight: "35px",
                maxWidth: "140px",
              }}
            >
              <a
                href="https://opensea.io"
                rel="noreferrer"
                title="Buy on OpenSea"
                target="_blank"
              >
                <img src={i9} alt="Opensea" width="35" height="35" border="0" />
              </a>
              &nbsp;
              <a
                href="https://etherscan.io"
                rel="noreferrer"
                title="Contract"
                target="_blank"
              >
                <img
                  src={i10}
                  alt="Etherscan"
                  width="35"
                  height="35"
                  border="0"
                />
              </a>
              &nbsp;
              <a
                href="https://discord.gg/vfywM82gSF"
                rel="noreferrer"
                title="Discord"
                target="_blank"
              >
                <img
                  src={i11}
                  alt="Discord Server"
                  width="35"
                  height="35"
                  border="0"
                />
              </a>
              &nbsp;
              <a
                href="https://twitter.com/DerabbitagsNFT"
                rel="noreferrer"
                title="DerabbitagsNFT"
                target="_blank"
              >
                <img
                  src={i12}
                  alt="Twitter"
                  width="35"
                  height="35"
                  border="0"
                />
              </a>
            </s.Container>

            <s.Container
              ai={"center"}
              jc={"center"}
              style={{
                marginLeft: "300px",
                flexWrap: "",

                overflow: "hidden",
                maxHeight: "140px",
                minHeight: "140px",
                maxWidth: "279px",
              }}
            >
              <s.SpacerLarge />
              <s.TextDescription style={{ textAlign: "left", fontSize: 30 }}>
                Mint Coming Soon!
              </s.TextDescription>
              <s.SpacerSmall />
            </s.Container>

            <StyledFrame src="https://cdn.klabsdev.com/derabbitags/sprite/Crowd.html"></StyledFrame>
          </s.Container>
        </s.Container>

        <div id="roadmap" />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />

        <s.Container
          style={{
            borderStyle: "double",
            borderColor: "white",
            borderWidth: "10px",
            padding: "40px",
            borderRadius: "5px",
          }}
        >
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={""}
            fd={"row"}
            style={{
              flexWrap: "",
              overflow: "",
              //width: "200px",
              maxHeight: "80px",
              minHeight: "80px",
            }}
          >
            <ReactRoundedImage
              image={i22}
              borderRadius="0"
              roundedColor=""
              imageWidth="111.7"
              imageHeight="137"
              roundedSize=""
              hoverColor=""
            />
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "55px",
              minHeight: "55px",
            }}
          >
            <s.TextDescription style={{ textAlign: "center", fontSize: 40 }}>
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                ABOUT
              </Gradient>
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "750px",
              minHeight: "200px",
            }}
          >
            <s.TextDescription style={{ textAlign: "left", fontSize: 26 }}>
              Derabbitags are a Derabbitive (derivative) of the Original{" "}
              <a
                href="https://playboyrabbitars.com"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                Playboy Rabbitars
              </a>{" "}
              and not affiliated with Playboy or Playboy NFTs. This is a
              community Derabbitive project designed to give back to the
              Rabbitar community by gifting 25% of the mint cost for each
              Derabbitag minted to random hodlers wallets. We will also be
              gifting 40% of the remaining value of the smart contract to one
              very lucky hodler once all Derabbitags have been minted. This is
              all 100% handled by the smart contract with no other interaction.
              If you own a Rabbitar you are automatically whitelisted, after
              presale the mint is open for everyone. To keep things fair, no one
              involved in the creation of the project is allowed to mint any
              Derabbitags for themselves. Post mint we will be airdropping 75%
              of the eth collected from secondary market royalties every
              quarter to hodlers wallets based on Derabbitag token IDs. Basically
              the more you own the better the chances of getting the airdrops post mint.
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "hidden",
              maxHeight: "1500px",
              maxWidth: "2490px",
            }}
          >
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i14}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container>
            <s.SpacerSmall />
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i15}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container>
            <s.SpacerSmall />
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i17}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container>
            <s.SpacerSmall />
            <s.Container1 ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i18}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container1>
            <s.SpacerSmall />
            <s.Container1 ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i21}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container1>
            <s.SpacerSmall />
            <s.Container1 ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i1}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
            </s.Container1>
          </s.Container>
          <s.SpacerLarge />
        </s.Container>
        <div id="team" />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.Container
          style={{
            borderStyle: "double",
            borderColor: "white",
            borderWidth: "10px",
            padding: "40px",
            borderRadius: "5px",
          }}
        >
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={""}
            fd={"row"}
            style={{
              flexWrap: "",
              overflow: "",
              //width: "200px",
              maxHeight: "80px",
              minHeight: "80px",
            }}
          >
            <ReactRoundedImage
              image={i22}
              borderRadius="0"
              roundedColor=""
              imageWidth="111.7"
              imageHeight="137"
              roundedSize=""
              hoverColor=""
            />
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "55px",
              minHeight: "55px",
            }}
          >
            <s.TextDescription style={{ textAlign: "center", fontSize: 40 }}>
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                MEET THE TEAM
              </Gradient>
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "750px",
              minHeight: "200px",
            }}
          >
            <s.TextDescription style={{ textAlign: "left", fontSize: 26 }}>
              From creating memorable imagery for content creators and battle
              rappers to branding businesses, Reya has assisted in elevating her
              clients appeal as well as producing designs that perfectly
              describe and represent their image. Her portfolio can be found on{" "}
              <a
                href="https://reyana.org"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                Reyana.org
              </a>{" "}
              however commissions are closed at this time.
            </s.TextDescription>
            <s.SpacerMedium />
            <s.TextDescription style={{ textAlign: "left", fontSize: 26 }}>
              Nick is an IT professional with more than 20 years of experience.
              His passion for technology and love of Rabbitars drives this
              project. When not coding he is busy "talking shit on the internet"
              for a living with his wife on their daily{" "}
              <a
                href="https://caffeine.tv/pigstand"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                broadcast
              </a>
              . If you have any questions about the project or just love to
              talk, feel free to join the broadcast and chat with us.
            </s.TextDescription>
          </s.Container>
          <s.SpacerMedium />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "hidden",
              maxHeight: "1500px",
              maxWidth: "2490px",
            }}
          >
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i13}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <s.TextDescription style={{ textAlign: "left", fontSize: 19 }}>
                Reya (Artist)
              </s.TextDescription>
            </s.Container>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
              <ReactRoundedImage
                image={i16}
                borderRadius=""
                roundedColor="#FF6663"
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor="#FF0000"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <s.TextDescription style={{ textAlign: "left", fontSize: 19 }}>
                Nick (Dev)
              </s.TextDescription>
            </s.Container>
          </s.Container>
        </s.Container>
        <div id="faq" />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.Container
          style={{
            borderStyle: "double",
            borderColor: "white",
            borderWidth: "10px",
            padding: "40px",
            borderRadius: "5px",
          }}
        >
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={""}
            fd={"row"}
            style={{
              flexWrap: "",
              overflow: "",
              //width: "200px",
              maxHeight: "80px",
              minHeight: "80px",
            }}
          >
            <ReactRoundedImage
              image={i22}
              borderRadius="0"
              roundedColor=""
              imageWidth="111.7"
              imageHeight="137"
              roundedSize=""
              hoverColor=""
            />
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "55px",
              minHeight: "55px",
            }}
          >
            <s.TextDescription style={{ textAlign: "center", fontSize: 40 }}>
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                FAQ
              </Gradient>
            </s.TextDescription>
          </s.Container>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <s.SpacerSmall />
          <s.Container
            ai={"top"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "1000px",
              minHeight: "60px",
            }}
          >
            <s.TextDescription style={{ textAlign: "left", fontSize: 26 }}>
              NFTs are a growing space, and it's completely normal to have
              questions. We hope to answer those below.
            </s.TextDescription>
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"top"}
            jc={"left"}
            fd={""}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "1200px",
              minHeight: "200px",
            }}
          >
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "lightblue" }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                "I'm new to this. How do I mint an NFT?"
              </Gradient>
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "" }}
            >
              The easiest way is to download Metamask (this is basically a
              decentralized wallet that holds your crypto and NFTs), then you
              need to purchase or send Eth to your Metamask wallet. After that,
              connect your wallet to the Derabbitags mint, Once connected click
              the 'MINT' button and follow the instructions in metamask.
            </s.TextDescription>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "lightblue" }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                "How much does a Derabbitag cost to mint?"
              </Gradient>
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "" }}
            >
              A Derabbitag will cost .08 Eth to mint, not including gas fees
              which vary due to network congestion.
            </s.TextDescription>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "lightblue" }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                "How many Derabbitags will be minted?"
              </Gradient>
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "" }}
            >
              A total of 11,953 Derabbitags will be minted to celebrate the year{" "}
              <a
                href="https://playboy.com"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                Playboy
              </a>{" "}
              was founded and to stay true to form of the original{" "}
              <a
                href="https://playboy.com"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                Playboy Rabbitars
              </a>
              .
            </s.TextDescription>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "lightblue" }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                {" "}
                "Is there a limit to how many Derabbitags I can mint?"
              </Gradient>
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "" }}
            >
              You will only be allowed to mint 5 Derabbitags per transaction.
              The amount of Derabbitags you own does not increase your chances
              of getting 25% of the mint cost or the 40% of the contract.
            </s.TextDescription>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "lightblue" }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                "Where can I find rarities?"
              </Gradient>
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "left", fontSize: 26, color: "" }}
            >
              Rarity information for Derabbitags will be available on{" "}
              <a
                href="https://rarity.tools"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                rarity.tools
              </a>{" "}
              shortly after all have been minted.
            </s.TextDescription>
          </s.Container>
        </s.Container>
        <div id="rarity" />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.Container
          style={{
            borderStyle: "double",
            borderColor: "white",
            borderWidth: "10px",
            padding: "40px",
            borderRadius: "5px",
          }}
        >
          <s.SpacerLarge />
          <s.Container
            ai={"center"}
            jc={""}
            fd={"row"}
            style={{
              flexWrap: "",
              overflow: "",
              maxHeight: "80px",
              minHeight: "80px",
            }}
          >
            <ReactRoundedImage
              image={i22}
              borderRadius="0"
              roundedColor=""
              imageWidth="111.7"
              imageHeight="137"
              roundedSize=""
              hoverColor=""
            />
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "55px",
              minHeight: "55px",
            }}
          >
            <s.TextDescription style={{ textAlign: "center", fontSize: 40 }}>
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                RARITY
              </Gradient>
            </s.TextDescription>
          </s.Container>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <s.SpacerSmall />
          <s.Container
            ai={"top"}
            jc={"left"}
            fd={"row"}
            style={{
              flexWrap: "wrap",
              overflow: "hidden",
              maxHeight: "250px",
              minHeight: "150px",
            }}
          >
            <s.TextDescription style={{ textAlign: "left", fontSize: 26 }}>
              The rarity of each Derabbitag depends on certain traits it
              receives when it's created. Each Derabbitag is unique in its own
              way. Derabbitags that lack traits might end up being some of the
              rarist. some will be rare, some very rare, and some will be one of
              ones, but ultimately just like{" "}
              <a
                href="https://playboyrabbitars.com"
                style={{ color: "#ff00a8", textDecoration: "none" }}
              >
                Rabbitars
              </a>{" "}
              One Derabbitag equals One Derabbitag, at the end of the day its
              about community, friendship, and togetherness.
            </s.TextDescription>
          </s.Container>
          <s.SpacerSmall />
          <s.Container
            ai={"top"}
            jc={"left"}
            fd={""}
            style={{
              flexWrap: "wrap",
              overflow: "Hidden",
              maxHeight: "900px",
              minHeight: "200px",
            }}
          >
            <s.Container
              ai={"top"}
              jc={"left"}
              fd={""}
              style={{
                flexWrap: "wrap",
                overflow: "Hidden",
                maxHeight: "900px",
                minHeight: "200px",
              }}
            >
              <s.SpacerSmall />
              <s.Container
                ai={"top"}
                jc={"left"}
                fd={""}
                style={{
                  flexWrap: "wrap",
                  //overflow: "Hidden",
                  maxHeight: "900px",
                  minHeight: "200px",
                  width: "2000px",
                }}
              >
                <s.Container
                  ai={"top"}
                  jc={"left"}
                  fd={""}
                  style={{
                    flexWrap: "wrap",
                    //overflow: "Hidden",
                    maxHeight: "900px",
                    minHeight: "200px",
                    width: "300px",
                  }}
                >
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      11,953
                    </Gradient>{" "}
                    &nbsp; &nbsp;&nbsp;Derabbitags
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      17
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Backgrounds
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      13
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Furs
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      6
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expressions
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      8
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accessories
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      7
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eye
                    Masks
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      4
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eye
                    Colors
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      15
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Glasses
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      6
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mouth
                    Mask
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      9
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Necklaces
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      7
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hats
                    and Ears
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      20
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Clothes
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <s.TextDescription
                    style={{ textAlign: "left", fontSize: 26, color: "" }}
                  >
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      {" "}
                      20
                    </Gradient>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One
                    of Ones
                  </s.TextDescription>
                </s.Container>

                <s.SpacerXSmall />

                <s.Container
                  ai={"center"}
                  jc={""}
                  fd={"row"}
                  style={{
                    flexWrap: "wrap",
                    //overflow: "hidden",
                    //maxHeight: "px",
                    //maxWidth: "px",
                    marginLeft: "250px",
                    marginTop: "75px",
                  }}
                >
                  <s.Container>
                    <ReactRoundedImage
                      image={i25}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                    <s.SpacerSmall />
                    <ReactRoundedImage
                      image={i24}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                  </s.Container>
                  <s.SpacerSmall />
                  <s.Container1>
                    <ReactRoundedImage
                      image={i23}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                    <s.SpacerSmall />
                    <ReactRoundedImage
                      image={i26}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                  </s.Container1>
                  <s.SpacerSmall />
                  <s.Container1>
                    <ReactRoundedImage
                      image={i27}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                    <s.SpacerSmall />
                    <ReactRoundedImage
                      image={i28}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                  </s.Container1>
                  <s.SpacerSmall />
                  <s.Container1>
                    <ReactRoundedImage
                      image={i30}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                    <s.SpacerSmall />
                    <ReactRoundedImage
                      image={i29}
                      borderRadius=""
                      roundedColor="#9EC1CF"
                      imageWidth="250"
                      imageHeight="250"
                      roundedSize=""
                      hoverColor="#CC99C9"
                    />
                  </s.Container1>

                  <s.SpacerLarge />
                </s.Container>
              </s.Container>
            </s.Container>
          </s.Container>
        </s.Container>
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.Container
          ai={"center"}
          jc={"center"}
          fd={"row"}
          style={{
            flexWrap: "wrap",
            overflow: "Hidden",
            maxHeight: "25px",
            minHeight: "25px",
          }}
        >
          <ReactRoundedImage
            image={i22}
            borderRadius=""
            roundedColor=""
            imageWidth="20"
            imageHeight="20"
            roundedSize=""
            hoverColor=""
          />
          &nbsp;&nbsp;
          <s.TextDescription style={{ textAlign: "left", fontSize: 18 }}>
            Derabbitags. All rights reserved.
          </s.TextDescription>
          &nbsp;&nbsp;
        </s.Container>
        <s.SpacerSmall />
      </s.Container>
    </s.Screen>
  );
}
export default App;
